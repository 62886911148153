
import * as React from "react"
import { Link } from "gatsby"

const NavList = (
  <>
    <li><Link to="/">Accueil</Link></li>
    <li><Link to="/le-club">Le Club</Link></li>
    <li><Link to="/l-ecurie">L'&Eacute;curie</Link></li>
    <li><Link to="/la-galerie">La Galerie</Link></li>
    <li><Link to="/les-tarifs">Tarifs</Link></li>
  </>
)

export function NavMD() {
  return (
    <>
      <ul className="content-end mt-2 space-y-3 text-sm text-white uppercase">
        {NavList}
        <li><a href="https://twitter.com/domainedutreuil" target="_blank" rel="noopener noreferrer">
          <img className="w-4 h-4" src="/images/icons/twitter.svg" alt="Twitter" />
        </a></li>
        <li><a href="https://www.facebook.com/domainedutreuil" target="_blank" rel="noopener noreferrer">
          <img className="w-4 h-4" src="/images/icons/facebook.svg" alt="Facebook" />
        </a></li>
      </ul>
    </>
  )
}

export function NavSM() {
  return (
    <>
      <ul className="flex justify-between mt-2 space-x-4 text-sm text-white uppercase">
        {NavList}
      </ul>
    </>
  )
}