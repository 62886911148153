import React from "react"
import { Provider } from "mobx-react";
import { Layout } from "./src/components/layout"
import ModalStore from "./src/store/modal-store"
const store = new ModalStore()

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props }) => (
  <Provider store={store}>
    <Layout {...props}>{element}</Layout>
  </Provider>
)

export default wrapPageElement