
import * as React from "react"
import { observer, inject } from "mobx-react"

import { Logo } from "./logo"
import { NavSM, NavMD } from "./nav"

const Navigation = ({ store: modalStore }) => {
  const handleClick = () => {
    modalStore.togglePanel()
  }

  return (
    <>
      <div className="hidden p-4 bg-gray-900 md:mx-auto lg:block">
        <div className="flex justify-between justify-items-center">
          <Logo />
          <NavSM />
          <div className="flex-wrap content-center hidden space-x-3 lg:flex">
            <a href="https://twitter.com/domainedutreuil" target="_blank" rel="noopener noreferrer">
              <img className="w-4 h-4" src="/images/icons/twitter.svg" alt="Twitter" />
            </a>
            <a href="https://www.facebook.com/domainedutreuil" target="_blank" rel="noopener noreferrer">
              <img className="w-4 h-4" src="/images/icons/facebook.svg" alt="Facebook" />
            </a>
          </div>
        </div>
      </div>

      <div className="block bg-gray-900 lg:hidden">
        <nav className="z-20 p-4">
          <div className="flex justify-between justify-items-center">
            <Logo />
            <button type="button" onClick={handleClick}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          {modalStore.isPanelOpen && <NavMD />}
        </nav>
      </div>
    </>
  )
}

export default inject('store')(observer(Navigation))