import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function Logo() {
  return (
    <>
      <Link to="/">
        <StaticImage
          className="w-24"
          src="../../static/images/logo_white.png"
          alt="Ghost &amp; Gatsby"
          formats={["auto", "webp", "avif"]}
        />
      </Link>
    </>
  )
}