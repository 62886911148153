// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../../../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-a-propos-jsx": () => import("./../../../src/pages/a-propos.jsx" /* webpackChunkName: "component---src-pages-a-propos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-l-ecurie-jsx": () => import("./../../../src/pages/l-ecurie.jsx" /* webpackChunkName: "component---src-pages-l-ecurie-jsx" */),
  "component---src-pages-la-galerie-jsx": () => import("./../../../src/pages/la-galerie.jsx" /* webpackChunkName: "component---src-pages-la-galerie-jsx" */),
  "component---src-pages-le-club-jsx": () => import("./../../../src/pages/le-club.jsx" /* webpackChunkName: "component---src-pages-le-club-jsx" */),
  "component---src-pages-les-tarifs-jsx": () => import("./../../../src/pages/les-tarifs.jsx" /* webpackChunkName: "component---src-pages-les-tarifs-jsx" */),
  "component---src-templates-blog-news-jsx": () => import("./../../../src/templates/blog-news.jsx" /* webpackChunkName: "component---src-templates-blog-news-jsx" */)
}

