import * as React from "react"

export function Footer() {
  return (
    <div className="relative h-32 mx-auto text-white bg-gray-800">
      <div className="absolute inset-x-0 bottom-0 content-center py-10 text-center">
        <p>domaineequestredutreuil.com &copy; 2019</p>
        <p>Photographies: Mathilde Penaud & Team Treuil - &copy; 2019 </p>
      </div>
    </div>
  )
}