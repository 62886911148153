import * as React from "react"
import { Header } from "./header"
import { Footer } from "./footer"
// import { Seo } from "./seo"

export function Layout({ children }) {
  return (
    <div className="w-full h-full text-base antialiased bg-gray-50">
      {/* <Seo /> */}
      <Header />
      {children}
      <Footer />
    </div>
  )
}