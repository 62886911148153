import { makeAutoObservable } from "mobx"

class ModalStore {
  isPanelOpen = false

  constructor() {
    makeAutoObservable(this)
  }

  togglePanel = () => {
    this.isPanelOpen = this.isPanelOpen ? false : true
  }
}

export default ModalStore